import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <h2><span role="img" aria-label="Forbidden sign">🚫</span> Not found</h2>
    <p>You just hit a route that doesn't exist.</p>
    <Link to="/">Return to the main page</Link>

  </Layout>
)

export default NotFoundPage