import React, { Component } from 'react'
import { Message, Label } from 'semantic-ui-react'
import Reaptcha from 'reaptcha'
import { Form, Input, TextArea } from 'formsy-semantic-ui-react'

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    recaptchaResponse: '',
    formFailed: false,
    formFailedMessage: '',
    formSucceeded: false,
    formIsSubmitting: false,
    allowSubmit: false,
  }

  recaptchaSitekey = process.env.RECAPTCHA_SITE_KEY

  render() {
    const {
      name,
      email,
      subject,
      message,
      formFailed,
      formFailedMessage,
      formSucceeded,
      formIsSubmitting,
      allowSubmit,
    } = this.state

    const errorLabel = <Label color="red" pointing="above" />

    return (
      <div>
        <Form
          ref={ref => (this.form = ref)}
          success={formSucceeded}
          error={formFailed}
          onValid={this.enableSubmitButton}
          onInvalid={this.disableSubmitButton}
          onValidSubmit={this.executeRecaptcha}
        >
          <Form.Group widths="equal">
            <Input
              required
              name="name"
              placeholder="Your name"
              icon="user"
              iconPosition="left"
              value={name}
              onChange={this.handleChange}
              errorLabel={errorLabel}
              validationErrors={{
                isDefaultRequiredValue: 'Name required',
              }}
            />
            <Input
              required
              type="email"
              name="email"
              placeholder="Your email address"
              icon="mail"
              iconPosition="left"
              value={email}
              onChange={this.handleChange}
              errorLabel={errorLabel}
              validations="isEmail"
              validationErrors={{
                isEmail: 'Invalid email',
                isDefaultRequiredValue: 'Email required',
              }}
            />
          </Form.Group>
          <Input
            required
            name="subject"
            placeholder="Subject"
            icon="pencil"
            iconPosition="left"
            value={subject}
            onChange={this.handleChange}
            errorLabel={errorLabel}
            validationErrors={{
              isDefaultRequiredValue: 'Subject required',
            }}
          />
          <TextArea
            required
            passRequiredToField={false}
            name="message"
            placeholder="Message"
            value={message}
            onChange={this.handleChange}
            errorLabel={errorLabel}
            validationErrors={{
              isDefaultRequiredValue: 'Message required',
            }}
          />
          <Form.Group>
            <Form.Button
              primary
              content="Submit"
              disabled={!allowSubmit}
              loading={formIsSubmitting}
            />
            <Form.Button
              basic
              type="reset"
              content="Reset"
              disabled={formIsSubmitting}
              onClick={this.resetForm}
            />
            <Reaptcha
              id="ContactFormRecaptcha"
              ref={ref => (this.recaptchaInstance = ref)}
              sitekey={this.recaptchaSitekey}
              size="invisible"
              badge="inline"
              isolated="false"
              inject="true"
              onLoad={this.recaptchaLoadCallback}
              onRender={this.recaptchaRenderCallback}
              onVerify={this.recaptchaVerifyCallback}
              onExpire={this.recaptchaExpireCallback}
              onError={this.recaptchaErrorCallback}
            />
          </Form.Group>

          <Message success>
            <Message.Header>Thanks for getting in touch!</Message.Header>
            <p>We'll respond as soon as possible.</p>
          </Message>
          <Message error>
            <Message.Header>Sorry! An error occurred.</Message.Header>
            <p>
              {formFailedMessage} . Check your connection and try and again.
            </p>
          </Message>
        </Form>
      </div>
    )
  }

  recaptchaLoadCallback = () => {
    console.log('Contact Loaded!')
  }
  recaptchaRenderCallback = () => {
    console.log('Contact Rendered!')
  }
  recaptchaExpireCallback = () => {
    console.log('Contact Expired!')
  }
  recaptchaErrorCallback = () => {
    console.log('Contact Error!')
  }

  executeRecaptcha = () => {
    this.recaptchaInstance.execute()
  }

  resetRecaptcha = () => {
    this.recaptchaInstance.reset()
  }

  recaptchaVerifyCallback = response => {
    this.setState({ formIsSubmitting: true })
    this.setState({ recaptchaResponse: response })
    this.handleSubmit()
    this.setState({ formIsSubmitting: false })
  }

  enableSubmitButton = () => {
    if (this.formIsSubmitting) return
    this.setState({ allowSubmit: true })
  }

  disableSubmitButton = () => {
    this.setState({ allowSubmit: false })
  }

  resetForm = () => {
    this.form.reset()
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSubmit = () => {
    const { name, email, subject, message, recaptchaResponse } = this.state

    fetch(process.env.FIREBASE_FUNCTION_URL, {
      method: 'POST',
      body: JSON.stringify({
        formType: 'contact',
        name: name,
        email: email,
        subject: subject,
        message: message.trim(),
        recaptchaResponse: recaptchaResponse,
      }),
    })
      .then(res => res.json())
      .then(json => {
        if (!json.result) {
          console.log(json.message)
          throw new Error(json.message)
        }
        this.setState({ formSucceeded: true })
        this.setState({ formFailed: false })
        this.resetRecaptcha()
        this.resetForm()
      })
      .catch(error => {
        this.setState({ formFailed: true })
        this.setState({ formSucceeded: false })
        this.setState({ formFailedMessage: error.message })
        this.resetRecaptcha()
      })
  }
}

export default ContactForm
