import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import { PrimaryLayout } from '@ballycastle-accommodation/shared-ui'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            subtitle
            description
          }
        }
      }
    `}
    render={data => (
      <>
        <PrimaryLayout children={children} data={data} />
      </>
    )}
  />
)

Layout.propTypes = {
  //children: PropTypes.func
  children: PropTypes.node.isRequired,
}

export default Layout
