import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Container, Grid } from 'semantic-ui-react'

import { Header, StickyNav } from '../../'

import 'semantic-ui-less/semantic.less'

const Layout = ({ children, data }) => (
  <div>
    <Helmet
      title={
        data.site.siteMetadata.title + ', ' + data.site.siteMetadata.subtitle
      }
      meta={[
        { name: 'description', content: data.site.siteMetadata.description },
      ]}
    />

    <Header siteTitle={data.site.siteMetadata.title} />

    <StickyNav />

    <Container>
      <Grid relaxed stackable>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          {children}
        </Grid.Column>
      </Grid>
    </Container>
  </div>
)

Layout.propTypes = {
  //children: PropTypes.func
  children: PropTypes.node.isRequired,
}

export default Layout
